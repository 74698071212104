$container-width: 360px;

#auth {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #291B67;


    .container {
        width: $container-width;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 50px;
        border-radius: 0 20px 20px 0;
        background-color: $white-color;
    }

    .banner {
        width: calc(100% - $container-width);
        height: 100vh;
        background-image: url(../../assets/images/bg.webp);
        background-size: 500px 500px;
        background-position: center;
        background-repeat: no-repeat;
    }
}

